import React, { useContext, useEffect, useRef } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { setAddToCartValidationErrors } from "../../../../redux/actions/productAction";
import { I18nContext } from "../../../../i18n";
import * as classes from "./Styles/CartValidationErrors.module.css";
import { MdClose } from "react-icons/md";

const CartValidationErrors = () => {
  const dispatch = useDispatch();

  const { translate } = useContext(I18nContext);

  const validationErrorsState = useSelector(
    state => state.productReducer.itemDetail.cartValidationErrors,
    shallowEqual
  );

  const handleModalClose = () => {
    dispatch(setAddToCartValidationErrors([]));
  };

  const firstElRef = useRef(null);

  useEffect(() => {
    if (firstElRef.current) {
      firstElRef.current.focus();
    }
  }, []);

  if (validationErrorsState && validationErrorsState.length > 0) {
    return (
      <div
        tabIndex="0"
        role="dialog"
        className={classes.container}
        onClick={handleModalClose}
        onTransitionEnd={() => {
          firstElRef.current && firstElRef.current.focus();
        }}
        ref={firstElRef}
      >
        <div
          className={classes.wrapper}
          onClick={e => {
            e.stopPropagation();
          }}
        >
          <div className={classes.head}>
            <h4>{translate("cartValidation.problemAddingToCart")}</h4>
            <i
              tabIndex="0"
              role="button"
              className="material-icons b-outline-on-focus"
              onClick={handleModalClose}
              onKeyPress={e => {
                if (e.key === "Enter") handleModalClose();
              }}
            >
              <MdClose />
            </i>
          </div>
          <div className={classes.body}>
            <h6>{translate("cartValidation.followingIsRequired")}</h6>
            <div className={classes.errTextWrapper}>
              {validationErrorsState.map((err, index) => (
                <p key={index} className={classes.errText}>
                  {err}
                </p>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default CartValidationErrors;
