import React from "react";
import * as classes from "./ChatTheSeller.module.css";
import { MdChat } from "react-icons/md";

export default function ChatTheSeller({ storeSellerData }) {
  const whatsapp =
    "https://ik.imagekit.io/ofb/ofb/whatsapp_R78MHvHF8.svg?tr=dpr-2,pr-true,f-auto,w-47";
  const linkedin =
    "https://ik.imagekit.io/ofb/ofb/linkedin_f_OGzK1Z9.svg?tr=dpr-2,pr-true,f-auto,w-47";
  const messenger =
    "https://ik.imagekit.io/ofb/ofb/messenger__pxgdM1sQ.png?tr=dpr-2,pr-true,f-auto,w-47";
  const [isEnquired, setIsEnquired] = React.useState(false);

  const { chat_communication = {} } = storeSellerData || {};

  const facebook_messanger_chat = chat_communication?.facebook_messanger_chat;
  const whatsapp_chat = chat_communication?.whatsapp_chat;
  const linkedin_chat = chat_communication?.linkedin_chat;

  const handleEnquire = event => {
    event.stopPropagation();
    setIsEnquired(true);
  };

  const handleEnquireClose = event => {
    event.stopPropagation();
    setIsEnquired(false);
  };

  if (facebook_messanger_chat && whatsapp_chat && linkedin_chat) {
    return (
      <div className="contact-seller-section">
        <div
          className={
            isEnquired
              ? `enquireAnimation verified-badge with-tip`
              : `verified-badge with-tip`
          }
          onClick={handleEnquire}
        >
          {isEnquired ? (
            <ul className={classes.enquire}>
              <li onClick={handleEnquireClose}>
                <a
                  href={`https://m.me/${facebook_messanger_chat}`}
                  target="_blank"
                >
                  <img
                    className={classes.enquireImage}
                    src={messenger}
                    alt="Social icon"
                  />
                </a>
              </li>
              <li onClick={handleEnquireClose}>
                <a href={`https://wa.me/${whatsapp_chat}`} target="_blank">
                  <img
                    src={whatsapp}
                    className={classes.enquireImage}
                    alt="Social icon"
                  />
                </a>
              </li>
              <li onClick={handleEnquireClose}>
                <a
                  href={`https://www.linkedin.com/company/${linkedin_chat}`}
                  target="_blank"
                >
                  <img
                    src={linkedin}
                    className={classes.enquireImage}
                    alt="Social icon"
                  />
                </a>
              </li>
            </ul>
          ) : (
            <span>
              <i class="material-icons">
                <MdChat />
              </i>
              Chat With The Rotary Club
            </span>
          )}
        </div>
      </div>
    );
  } else if (facebook_messanger_chat && whatsapp_chat) {
    return (
      <div className="contact-seller-section">
        <div
          className={
            isEnquired
              ? `enquireAnimation verified-badge with-tip`
              : `verified-badge with-tip`
          }
          onClick={handleEnquire}
        >
          {isEnquired ? (
            <ul className={classes.enquire}>
              <li onClick={handleEnquireClose}>
                <a
                  href={`https://m.me/${facebook_messanger_chat}`}
                  target="_blank"
                >
                  <img
                    src={messenger}
                    className={classes.enquireImage}
                    alt="Social icon"
                  />
                </a>
              </li>
              <li onClick={handleEnquireClose}>
                <a href={`https://wa.me/${whatsapp_chat}`} target="_blank">
                  <img
                    src={whatsapp}
                    className={classes.enquireImage}
                    alt="Social icon"
                  />
                </a>
              </li>
            </ul>
          ) : (
            <span>
              <i class="material-icons">
                <MdChat />
              </i>
              Chat With The Rotary Club
            </span>
          )}
        </div>
      </div>
    );
  } else if (facebook_messanger_chat && linkedin_chat) {
    return (
      <div className="contact-seller-section">
        <div
          className={
            isEnquired
              ? `enquireAnimation verified-badge with-tip`
              : `verified-badge with-tip`
          }
          onClick={handleEnquire}
        >
          {isEnquired ? (
            <ul className={classes.enquire}>
              <li onClick={handleEnquireClose}>
                <a
                  href={`https://www.linkedin.com/company/${linkedin_chat}`}
                  target="_blank"
                >
                  <img
                    src={linkedin}
                    className={classes.enquireImage}
                    alt="Social icon"
                  />
                </a>
              </li>
            </ul>
          ) : (
            <span>
              <i class="material-icons">
                <MdChat />
              </i>
              Chat With The Rotary Club
            </span>
          )}
        </div>
      </div>
    );
  } else if (whatsapp_chat && linkedin_chat) {
    return (
      <div className="contact-seller-section">
        <div
          className={
            isEnquired
              ? `enquireAnimation verified-badge with-tip`
              : `verified-badge with-tip`
          }
          onClick={handleEnquire}
        >
          {isEnquired ? (
            <ul className={classes.enquire}>
              <li onClick={handleEnquireClose}>
                <a href={`https://wa.me/${whatsapp_chat}`} target="_blank">
                  <img
                    src={whatsapp}
                    className={classes.enquireImage}
                    alt="Social icon"
                  />
                </a>
              </li>
              <li onClick={handleEnquireClose}>
                <a
                  href={`https://www.linkedin.com/company/${linkedin_chat}`}
                  target="_blank"
                >
                  <img
                    src={linkedin}
                    className={classes.enquireImage}
                    alt="Social icon"
                  />
                </a>
              </li>
            </ul>
          ) : (
            <span>
              <i class="material-icons">
                <MdChat />
              </i>
              Chat With The Rotary Club
            </span>
          )}
        </div>
      </div>
    );
  } else if (facebook_messanger_chat) {
    return (
      <div className="contact-seller-section">
        <div
          className={
            isEnquired
              ? `enquireAnimation verified-badge with-tip`
              : `verified-badge with-tip`
          }
          onClick={handleEnquire}
        >
          {isEnquired ? (
            <ul className={classes.enquire}>
              <li onClick={handleEnquireClose}>
                <a
                  href={`https://www.facebook.com/messages/t${facebook_messanger_chat}`}
                  target="_blank"
                >
                  <img
                    src={messenger}
                    className={classes.enquireImage}
                    alt="Social icon"
                  />
                </a>
              </li>
              {/*) : null}*/}
            </ul>
          ) : (
            <span>
              <i class="material-icons">
                <MdChat />
              </i>
              Chat With The Rotary Club
            </span>
          )}
        </div>
      </div>
    );
  } else if (whatsapp_chat) {
    return (
      <div className="contact-seller-section">
        <div
          className={
            isEnquired
              ? `enquireAnimation verified-badge with-tip`
              : `verified-badge with-tip`
          }
          onClick={handleEnquire}
        >
          {isEnquired ? (
            <ul className={classes.enquire}>
              <li onClick={handleEnquireClose}>
                <a href={`https://wa.me/${whatsapp_chat}`} target="_blank">
                  <img
                    src={whatsapp}
                    className={classes.enquireImage}
                    alt="Social icon"
                  />
                </a>
              </li>
            </ul>
          ) : (
            <span>
              <i class="material-icons">
                <MdChat />
              </i>
              Chat With The Rotary Club
            </span>
          )}
        </div>
      </div>
    );
  } else if (linkedin_chat) {
    return (
      <div className="contact-seller-section">
        <div
          className={
            isEnquired
              ? `enquireAnimation verified-badge with-tip`
              : `verified-badge with-tip`
          }
          onClick={handleEnquire}
        >
          {isEnquired ? (
            <ul className={classes.enquire}>
              <li onClick={handleEnquireClose}>
                <a
                  href={`https://www.linkedin.com/company/${linkedin_chat}`}
                  target="_blank"
                >
                  <img
                    src={linkedin}
                    className={classes.enquireImage}
                    alt="Social icon"
                  />
                </a>
              </li>
            </ul>
          ) : (
            <span>
              <i class="material-icons">
                <MdChat />
              </i>
              Chat With The Rotary Club
            </span>
          )}
        </div>
      </div>
    );
  } else {
    return null;
  }
  // console.info("storeSellerData", storeSellerData);

  // if (storeSellerData && storeSellerData.facebook)
  //   return (
  //     <div className="contact-seller-section">
  //       <div
  //         className={
  //           isEnquired
  //             ? `enquireAnimation verified-badge with-tip`
  //             : `verified-badge with-tip`
  //         }
  //         onClick={handleEnquire}
  //       >
  //         {isEnquired ? (
  //           <ul className={classes.enquire}>
  //             {storeSellerData && storeSellerData.facebook ? (
  //               <li onClick={handleEnquireClose}>
  //                 <a
  //                   href={`https://m.me/${
  //                     storeSellerData.facebook.split("facebook.com/")[1]
  //                   }`}
  //                   target="_blank"
  //                 >
  //                   <img src={messenger} alt="Social icon" />
  //                 </a>
  //               </li>
  //             ) : null}
  //             {/*   <li onClick={handleEnquireClose}>
  //                 <a href="https://wa.me/6472155895" target="_blank">
  //                   <img src={whatsapp} alt="Social icon" />
  //                 </a>
  //               </li>
  //
  //               <li onClick={handleEnquireClose}>
  //                 <a
  //                   href="https://www.linkedin.com/company/avetti-com-corporation"
  //                   target="_blank"
  //                 >
  //                   <img src={linkedin} alt="Social icon" />
  //                 </a>
  //               </li> */}
  //           </ul>
  //         ) : (
  //           <span>
  //             <i class="material-icons"><MdChat/></i> Chat With The Rotary Club
  //           </span>
  //         )}
  //       </div>
  //     </div>
  //   );
  // else return null;
}
